import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from "react-helmet"
import RecipeSide from '../components/RecipeSide'

export default function recipeSide() {
    return (
        <Layout>
            {/* For page head */}
            <Helmet>
                <title>Maker Recipe Sdn. Bhd. - Side Dishes</title>
            </Helmet>

            <RecipeSide />
        </Layout>
    )
}