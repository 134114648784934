import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'gatsby'
import '../styles/recipelist.css'

export default function RecipeSide() {
    return (
        <div>
            <div className="RecipeListFirstLayer">
                <Container>
                    <Row>
                        <Col lg="6">
                            <Row>
                                <Col md="12" className="RecipeMonth-Title">
                                    <h2>Recipe of the month!</h2>
                                </Col>
                                <Col md="12" className="RecipeMonth-Subtitle">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed 
                                        do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                                        irure dolor in reprehenderit in voluptate velit esse cillum 
                                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat 
                                        cupidatat non proident, sunt in culpa qui officia deserunt 
                                        mollit anim id est laborum.
                                    </p>
                                </Col>
                                <Col md="12" className="RecipeMonth-Button">
                                    <Button>
                                        <Link to="/recipe/recipe-dish">View Recipe</Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Main Course Recipe list page second layer */}
            <div className="RecipeListSecondLayer">
                <Container>
                    <Row>
                        <Col sm="12" md="12" lg={{ size: 6, offset: 3}}>
                            <Row>
                                <Col md="3" className="RecipeList-Button">
                                    <Button>
                                        <Link to="/recipe-list">All Recipe</Link>
                                    </Button>
                                </Col>
                                <Col md="3" className="RecipeList-Button ">
                                    <Button>
                                        <Link to="/recipe-main">Main Course</Link>
                                    </Button>
                                </Col>
                                <Col md="3" className="RecipeList-Button ActiveRecipeList-Button">
                                    <Button>
                                        <Link to="/recipe-side">Side Dishes</Link>
                                    </Button>
                                </Col>
                                <Col md="3" className="RecipeList-Button">
                                    <Button>
                                        <Link to="/recipe-snack-starter">Snack & Starters</Link>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Recipe Cards */}
                    <Row className="RecipeFirstRow">
                        <Col lg="3">
                            <div className="RecipeCard">
                                <Container>
                                    <Row className="recipeImage-Container">
                                        <Col md="12" className="RecipeCategory">
                                            <Button>
                                                <Link to="/recipe-category/side-dishes">Side Dishes</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="RecipeName">
                                            <h2>Maker Recipe Side Dish 1</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col sm="12" md={{ size: 8, offset: 2 }} className="RecipeCard-Button">
                                                    <Button>
                                                        <Link to="/recipe/recipe-dish">View Recipe</Link>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="RecipeCard">
                                <Container>
                                    <Row className="recipeImage-Container">
                                        <Col md="12" className="RecipeCategory">
                                            <Button>
                                                <Link to="/recipe-category/side-dishes">Side Dishes</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="RecipeName">
                                            <h2>Maker Recipe Side Dish 2</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col sm="12" md={{ size: 8, offset: 2 }} className="RecipeCard-Button">
                                                    <Button>
                                                        <Link to="/recipe-list">View Recipe</Link>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="RecipeCard">
                                <Container>
                                    <Row className="recipeImage-Container">
                                        <Col md="12" className="RecipeCategory">
                                            <Button>
                                                <Link to="/recipe-category/side-dishes">Side Dishes</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="RecipeName">
                                            <h2>Maker Recipe Side Dish 3</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col sm="12" md={{ size: 8, offset: 2 }} className="RecipeCard-Button">
                                                    <Button>
                                                        <Link to="/recipe-list">View Recipe</Link>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="RecipeCard">
                                <Container>
                                    <Row className="recipeImage-Container">
                                        <Col md="12" className="RecipeCategory">
                                            <Button>
                                                <Link to="/recipe-category/side-dishes">Side Dishes</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="RecipeName">
                                            <h2>Maker Recipe Side Dish 4</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <Row>
                                                <Col sm="12" md={{ size: 8, offset: 2 }} className="RecipeCard-Button">
                                                    <Button>
                                                        <Link to="/recipe-list">View Recipe</Link>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
